.modalHolder {
    position: fixed;
    inset: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.modal .closeButton {
    position: absolute;
    top: 0;
    right: 0;
}

.modal .red {
    background: black;
    color: red;
}

.modal .blue {
    background: blue;
    color: red;
}

.modal .off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(black, 0.2);
}

h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
}

.content {
    padding: 1rem;
}

.modal .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;

}

.modal .actions button {
    border: 0;
    background: #78f89f;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
}

#centered-toggle-button {
    position: absolute;
}

.modal .quadrantGrid {
    display: grid;
}

span.custom_radio {
    border: 1px solid black;
    border-radius: 5px;
    padding: 2px;
    margin: 3px;
    background-color: white;
}

span.custom_radio:has(input:checked) {
    background-color: aqua;
}

span.custom_radio:has(input:focus) {
	box-shadow: 0 0 0 3px #ffbf47;
}

span.custom_radio:has(input:disabled) {
	color: grey;
    border: 1px dashed grey;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
/* span.custom_radio input {
    display: none;
} */