.allianceBoards {
  display: flex;
  justify-content: space-around;
}

.character-card {
  position: relative;
  border: 5px solid rgb(42, 84, 104);
  border-radius: 10px;
  padding: 5px;
  color: #ffffff;
  background-color: rgb(42, 84, 104);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  max-width: 500px;
  max-height: 125px;
  margin-bottom: 10px;
}

.character-card p {
  margin-left: 10px;
  margin-top: 0;
}

.character-card.selected {
  border: 5px solid #1ca439;
}

.character-card-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
  align-self: start;
}

span.faded {
  opacity: 25%;
}

div.action-points {
  display: flex;
  flex-direction: row-reverse;
}

div.ability-list {
  display: inline-flex;
  flex-direction: row;
}

button.ability {
  margin-right: 5px;
}

button.selected {
  border-style: dashed 
}

.damaged-0 {
  animation: 1s showDamage-0 linear forwards;
}

@keyframes showDamage-0 {
  from { background-color: red; }
	to {  }
}

.damaged-1 {
  animation: 1s showDamage-1 linear forwards;
}

@keyframes showDamage-1 {
  from { background-color: red; }
	to {  }
}

.spin-0 {
  animation: 1s spin-0 linear infinite;
}

@keyframes spin-0 {
  from { rotate: 0;}
  to { rotate: 360;}
}

.spin-1 {
  animation: 1s spin-1 linear infinite;
}

@keyframes spin-1 {
  from { rotate: 0;}
  to { rotate: 360;}
}

.animation-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  margin: 0;
  /* transform: translate(-50%, -50%); */
  animation: spinner 1.2s normal;
}
/* .lds-hourglass {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  animation: lds-hourglass 1.2s infinite;
} */
/* .lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
} */
@keyframes spinner {
  0% {
    transform: rotate(0) scale(1.0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg) scale(1.5);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg) scale(2.0);
  }
}

.halo {
  text-shadow: 1px 1px 2px black, 0 0 0.5em yellow, 0 0 1em white;
}